import React from 'react'
import { Breadcrumbs, Hero, Page, Projects } from '@components'

const meta = {
  title: 'Izdvojeni projekti | Protupožarna zaštita - FSB d.o.o.',
  description: 'Odradili smo niz zahtjevnih projekata koji uključuju stambeno – poslovne, industrijske, farmaceutske, bolničke, hotelske, telekomunikacijske i druge građevine.',
}

const Homepage = () => (
  <Page
    isHomepage
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/projekti/', name: 'Projekti'},
      ]}
    />
    <Hero
      slim
      description={meta.description}
      subtitle="Naši projekti su odraz našeg poslovanja"
      title="Izdvojeni projekti"
    />
    <Projects />
  </Page>
)

export default Homepage